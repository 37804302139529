import { Link } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import Logo from '../../elements/Logo';
import MobileMenu from './MobileMenu';
import Nav from './Nav';
import StickyHeader from './StickyHeader';

const ReferHeader = () => {
  const sticky = StickyHeader(100);

  const MobileMenuHandler: MouseEventHandler = (e) => {
    e.stopPropagation();
    document.querySelector('.mobilemenu-popup')?.classList.toggle('show');
    document.querySelector('body')?.classList.toggle('mobilemenu-show');
  };

  return (
    <>
      <header className="header banco-header header-style-4">
        <div className={`banco-mainmenu ${sticky ? 'banco-sticky' : ''}`}>
          <div className="container">
            <div className="header-navbar">
              <div className="header-logo">
                <Logo limage="/images/logo-sticky.png" simage="/images/logo-sticky.png" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu MobileHandler={MobileMenuHandler} />
    </>
  );
};
export default ReferHeader;
