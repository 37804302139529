import React from 'react';
import ReferHeader from '../components/header/ReferHeader';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Refer2022Form from '../elements/Activities/Refer2022Form';
import CheerSvg from '../../static/svg/cheerSvg';
import Footer from '../components/footer/Footer';
import { Link } from 'gatsby';

const Refer2022 = () => {
  return (
    <Layout>
      <Seo
        title="Customer Referral Program | banco"
        description='Say "banco!" on your next business meeting. Unlimited Referrals, Unlimited Rewards.'
      />
      <ReferHeader />

      <div className="banner banner-refer-2022">
        <div className="red-block" />
        <div className="banner-right">
          <div className="img-container">
            <div className="dot-group top-right">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <img src="/images/refer2022/banner.jpg" alt="Refer" />
            <div className="dot-group bottom-left">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
          <div className="popover">
            <div className="popover-arrow"></div>
            <div className="popover-body">
              <img src="/images/refer2022/cheer.png" />
              Campaign Period: <br />
              <strong>1 August to 31 October 2022</strong>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <div className="banner-content">
                <h1 className="title">
                  Share our Referral Program
                  <br />
                  Get Rewarded Together
                </h1>
                <p>
                  Get rewarded up to <em>$250*</em> each successful referral. No cap.
                </p>
                <div className="banner-form">
                  <div className="contact-form-box shadow-box">
                    <Refer2022Form />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 banner-right-container"></div>
          </div>
        </div>
      </div>

      <div className="row refer-middle">
        <div className="col-md-6 col-sm-12 refer-middle-left">
          <div className="container">
            <h2>
              Unlimited Referrals <br />
              Unlimited Rewards
            </h2>
            <p>Say “banco!” on your next business meeting</p>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 refer-middle-right">
          <div className="refer-middle-wrapper">
            <div className="container">
              <p>For each successful referral you will receive:</p>
              <ul>
                <li>
                  <em>$100</em> shopping vouchers when they finance their first invoice
                </li>
                <li>
                  <em>$100</em> shopping vouchers when the referral invoices amount is greater than
                  $300,000
                </li>
              </ul>
              <p>The referred business will receive $50 when they complete their first drawdown.</p>

              <div className="label">Up to $250 per referral!</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row refer-tc">
        <div className="circle-bg" />
        <div className="circle-bg right-bottom" />
        <div className="container">
          <h3>Terms and Conditions</h3>
          <p>
            1. banco Referral Program is open exclusively to new referrals (customers who do not
            have an account with banco) who register for a new account and finance an invoice within
            the campaign period.
          </p>
          <p>
            2. For each eligible referral, the referrer is entitled to receive shopping vouchers
            from FairPrice based on the invoice drawdown amount during the campaign period.
          </p>
          <p className="red">$100 Shopping Vouchers for Invoice Amount lesser than $300,000</p>
          <p>
            3. The referral is entitled to receive $50 Shopping Vouchers upon completing first
            drawdown within campaign period.
          </p>
          <p>
            4. The campaign period refers to the period from <em>August to October 2022</em>.
          </p>
          <p>
            5. It is the responsibility of the referrer to inform the referred prospect about the
            Scheme and obtain referred prospect's consent of disclosure of any relevant information
            for facility application at banco.
          </p>
          <p>
            6. Related or associated companies of referrer will not be qualified as referred
            prospect for the Scheme.
          </p>
          <p>
            7. banco reserves the right to remedy, including cancellation of accounts or
            applications, if fraud, tampering or violation of these terms of banco's terms and
            conditions, or if technical errors are suspected.
          </p>
          <p>
            8. Eligible customers consent to banco collection and use of personal data according to
            banco{' '}
            <Link to="/privacy-policy">
              <em>Privacy Policy</em>
            </Link>
            .
          </p>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default Refer2022;
