import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import libphonenumber from 'google-libphonenumber';
import intlTelInput from 'intl-tel-input';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { SuccessSvg } from '../../../static/svg/invoiceSvgCom';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

interface IFormInputs {
  referrer_name?: string;
  referrer_email?: string;
  referrer_phone?: string;
  company_name?: string;
  company_contact_name?: string;
  company_contact_email?: string;
  company_contact_phone?: string;
  company_preferred_contact_mode?: string;
}

declare let grecaptcha: any;

const Refer2022Form = () => {
  const [phone, setPhone] = useState<intlTelInput.Plugin>();
  const [companyPhone, setCompanyPhone] = useState<intlTelInput.Plugin>();
  const [show, setShow] = useState(false);

  Yup.addMethod(Yup.string, 'phone', function (message) {
    return this.test({
      name: 'phone',
      exclusive: true,
      message: message || 'must be a phone number',
      test: async function (value) {
        if (!phone || !value) {
          return false;
        }
        try {
          const area = phone.getSelectedCountryData().iso2.toUpperCase();
          const number = phoneUtil.parse(value, area);
          return phoneUtil.isValidNumber(number);
        } catch (error) {}
        return false;
      },
    });
  });

  Yup.addMethod(Yup.string, 'companyPhone', function (message) {
    return this.test({
      name: 'companyPhone',
      exclusive: true,
      message: message || 'must be a phone number',
      test: async function (value) {
        if (!companyPhone || !value) {
          return false;
        }
        try {
          const area = companyPhone.getSelectedCountryData().iso2.toUpperCase();
          const number = phoneUtil.parse(value, area);
          return phoneUtil.isValidNumber(number);
        } catch (error) {}
        return false;
      },
    });
  });

  const schema = Yup.object().shape({
    referrer_name: Yup.string().required('Required'),
    referrer_email: Yup.string().email('Invalid email address').required('Required'),
    referrer_phone: Yup.string().required('Required').phone('The phone number is invalid.'),
    company_name: Yup.string().required('Required'),
    company_contact_name: Yup.string().required('Required'),
    company_contact_email: Yup.string().email('Invalid email address').required('Required'),
    company_contact_phone: Yup.string()
      .required('Required')
      .companyPhone('The phone number is invalid.'),
    company_preferred_contact_mode: Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const mobileInput = document.querySelector('#mobile');
    if (mobileInput) {
      const phoneState = intlTelInput(mobileInput, {
        initialCountry: 'auto',
        geoIpLookup: (callback) => {
          axios
            .get<
              undefined,
              {
                data: {
                  country_code: string;
                };
              }
            >('https://ipapi.co/json/', {
              responseType: 'json',
            })
            .then((resp) => {
              if (resp && resp.data && resp.data.country_code) {
                callback(resp.data.country_code.toLowerCase());
              } else callback('sg');
            });
        },
        preferredCountries: ['sg', 'cn', 'hk', 'us'],
      });
      setPhone(phoneState);
    }

    const companyMobileInput = document.querySelector('#companyMobile');
    if (companyMobileInput) {
      const phoneState = intlTelInput(companyMobileInput, {
        initialCountry: 'auto',
        geoIpLookup: (callback) => {
          axios
            .get<
              undefined,
              {
                data: {
                  country_code: string;
                };
              }
            >('https://ipapi.co/json/', {
              responseType: 'json',
            })
            .then((resp) => {
              if (resp && resp.data && resp.data.country_code) {
                callback(resp.data.country_code.toLowerCase());
              } else callback('sg');
            });
        },
        preferredCountries: ['sg', 'cn', 'hk', 'us'],
      });
      setCompanyPhone(phoneState);
    }
  }, []);

  const onSubmit = (data: IFormInputs) => {
    if (!phone) {
      return;
    }

    const area = phone.getSelectedCountryData().dialCode;

    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute('6Le4KpEaAAAAACJw4liW38ANFo2whC6FjqdWhDdX', {
          action: 'submit',
        });
        const result = await axios.post(
          `${process.env.API_URL}/api/refer-2022s`,
          {
            ...data,
            token,
            referrer_phone: `${area}${data.referrer_phone}`,
            company_contact_phone: `${area}${data.company_contact_phone}`,
          },
          {
            responseType: 'json',
          },
        );

        if (result && result.data && result.data.status) {
          setShow(true);
        }
      } catch (error) {}
    });
  };

  return (
    <div className="contact-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h3>Your details</h3>
            <input {...register('referrer_name')} placeholder="Your name *" />
            <p>{errors.referrer_name?.message}</p>

            <input
              {...register('referrer_email')}
              type="email"
              placeholder="Your email address *"
            />
            <p>{errors.referrer_email?.message}</p>

            <input {...register('referrer_phone')} id="mobile" placeholder="Your phone number *" />
            <p>{errors.referrer_phone?.message}</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <h3>Referral details</h3>
            <input {...register('company_name')} placeholder="Company name *" />
            <p>{errors.company_name?.message}</p>

            <input {...register('company_contact_name')} placeholder="Contact name *" />
            <p>{errors.company_contact_name?.message}</p>

            <input
              {...register('company_contact_email')}
              type="email"
              placeholder="Email address *"
            />
            <p>{errors.company_contact_email?.message}</p>

            <input
              {...register('company_contact_phone')}
              id="companyMobile"
              placeholder="Phone number *"
            />
            <p>{errors.company_contact_phone?.message}</p>

            <label htmlFor="">Preferred mode of contact</label>
            <label className="radio-box">
              <input type="radio" {...register('company_preferred_contact_mode')} value="email" />
              Email
            </label>
            <label className="radio-box">
              <input type="radio" {...register('company_preferred_contact_mode')} value="phone" />
              Phone
            </label>
            <p>{errors.company_preferred_contact_mode?.message}</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>

      <Modal className="contact-modal" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <button onClick={() => setShow(false)}>
            <img src="/images/close-img.png" alt="" />
          </button>
        </Modal.Header>
        <Modal.Body>
          {SuccessSvg({})}
          <p>We have received your message.</p>
          <p>We’ll get back to you as soon as possible.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShow(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Refer2022Form;
